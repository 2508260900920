/* html {
    background-color: #000;
} */

.transition-enter {
    opacity: 0;
    /* transform: translateY(-100vh) !important; */
    z-index: 1 !important;
}

.transition-enter.transition-enter-active {
    opacity: 1;
    /* transform: translateY(0vh) !important; */
    transition: opacity 200ms ease-in;
    /* transition: transform 500ms ease-in !important; */
}