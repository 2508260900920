@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

* input {  
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  -o-user-select: text;
  user-select: text;
}

.btn-add-remove-quantity {
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-remove-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
}

button {
  border-radius: 0px !important;
}

html,
body,
#root {
  min-height: 100%;
  user-select: none !important;
  padding: 0!important;
  margin: 0!important;
}

body {
  background: #fafafa;
  background-size: cover;
  -webkit-font-smoothing: antialiased !important;
}

body,
input,
button {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.switch-wrapper {
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
  user-select: none;
}

body {
  margin: 0 auto !important;
  /* max-width: 520px !important; */
}

.fixed-bottom {
  margin: 0 auto !important;
  background: white;
  /* max-width: 520px !important; */
}

.fixed-bottom button {
  height: 45px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-bottom button svg {
  margin: 5px;
}

.product-description {
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  margin: 0 10px 10px 10px;
}